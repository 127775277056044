<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <template v-if="config.id == null && isNewConfig">
            <h1>
              <div>
                Создание конфигурации конкурса
              </div>
            </h1>
            <div class="chapterName">Наименование конкурса</div>
            <v-text-field label="Наименование" v-model="config.content.sections[0].title" style="margin-bottom: 15px"></v-text-field>
            <div class="save-buttons mt-24">
              <v-btn color="primary" outlined @click="$router.push('/config/event')">Отмена</v-btn>
              <v-btn color="primary" @click="createConfig(); $forceUpdate()">Далее</v-btn>
            </div>
          </template>
          <template v-else>
            <template v-if="isNewConfig">
              <h1>
                <div>Создание конфигурации конкурса
                  <v-btn outlined @click="$refs['fileInput'].click()">загрузить файл конфигурации</v-btn>
                  <input ref="fileInput" style="display: none" type="file" @change="loadJson">
                </div>
              </h1>
            </template>
            <template v-else>
              <h1>
                <div>Редактирование<br> конфигурации id {{ config.id }}</div>
              </h1>
              <div class="creation-date">
                Дата создания<span>{{ config.createdDate | dateFormat }}</span>
              </div>
              <div class="creation-date">
                Дата изменения<span v-if="config.lastModifiedDate">{{ config.lastModifiedDate | dateFormat }}</span>
                <span v-else>—</span>
              </div>
            </template>
            <div class="chapterName" style="margin-top: -20px">Наименование конкурса</div>
            <v-text-field label="Наименование" v-model="config.content.sections[0].title"></v-text-field>
            <div class="chapterName" style="margin-top: 5px">Описание</div>
            <v-text-field label="Описание" v-model="config.content.sections[0].description"></v-text-field>
            <div class="chapterName" style="margin-top: 5px">Код (уникальный для каждого конкурса)</div>
            <v-text-field label="Описание" v-model="config.content.sections[0].context" style="margin-bottom: 35px"></v-text-field>

            <v-row>
              <v-col cols="12" lg="3">
                <div class="date-range-label" style="font-weight: 600; font-size: 16px;">Период проведения</div>
                <v-menu v-model="startDateMenu"
                        :close-on-content-click="false"
                        min-width="auto"
                        offset-y
                        transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        :value="config.content.sections[0].beginDate | dateFormat"
                        clearable
                        label="Начало"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="config.content.sections[0].beginDate"
                      :max="maxDate"
                      :min="minDate"
                      locale="ru-RU"
                      no-title
                      scrollable
                      @input="startDateMenu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu v-model="endDateMenu"
                        :close-on-content-click="false"
                        min-width="auto"
                        offset-y
                        transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        :value="config.content.sections[0].endDate | dateFormat"
                        clearable
                        label="Окончание"
                        prepend-icon="mdi-calendar"
                        readonly
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="config.content.sections[0].endDate"
                      :max="maxDate"
                      :min="minDate"
                      locale="ru-RU"
                      no-title
                      scrollable
                      @input="endDateMenu=false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <div class="chapterName mb-2">Файлы</div>
            <div>
              <v-row class="file-row align-center" v-for="attachment in attachments" :key="attachment.id">
                <v-col cols="12" lg="4">
                  <a @click="downloadAttachment(attachment)" download class="file-link">
                    <v-icon class="icon-item icon-file-document-outline"></v-icon>
                    {{ attachment.name }}
                  </a>
                </v-col>
                <v-col cols="12" lg="5" class="d-flex align-center">
                  <span class="mr-2">Для роли</span>
                  <v-select
                      outlined
                      :items="attachmentType"
                      item-text="title"
                      item-value="type"
                      v-model="attachment.role"
                      @change="$forceUpdate()"
                  ></v-select>
                  <div class="delete ml-2" @click="deleteAttachment(attachment)">
                    <v-icon class="icon-item icon-delete-outline"></v-icon>
                  </div>
                </v-col>
              </v-row>

              <input type="file" ref="attachmentInput" style="display: none" @change="addAttachment()">
              <a @click="$refs['attachmentInput'].click()" class="configAdd">+ добавить файл</a>
            </div>

            <div class="chapterName" style="margin-bottom: -60px">Параметры конкурса</div>
            <div class="list config">
              <div v-for="(nomination, nominationIndex) in config.content.sections[0].nominations" :key="'nomination_' + nominationIndex" class="list-item-wrap">
                <v-expansion-panels>
                  <v-expansion-panel :key="'nomination_' + nominationIndex">
                    <v-expansion-panel-header inside @keyup.space.prevent expand-icon="">
                      <div class="list-item">
                        <div class="list-item-name">
                          <button class="toggleSwitch">
                            <svg class="closed" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                            </svg>
                            <svg class="opened" fill="none" height="24" viewBox="0 0 24 24" width="24"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                            </svg>
                          </button>
                          <span>
                            <h4 v-if="nominationIndex === 0">Номинация</h4>
                            <q>{{ nominationIndex + 1 }}.</q>
                            <v-text-field v-model="nomination.name" @click.stop="" label="Наименование номинации"></v-text-field>
                          </span>
                          <div v-if="config.content.sections[0].nominations.length > 1"
                               class="delete"
                               @click="nominationDown(nominationIndex)"
                               @click.stop=""
                          >
                            <v-icon class="icon-item icon-arrow-down-bold-outline"></v-icon>
                          </div>
                          <div v-if="config.content.sections[0].nominations.length > 1"
                               class="delete ml-5"
                               @click="nominationUp(nominationIndex)"
                               @click.stop=""
                          >
                            <v-icon class="icon-item icon-arrow-up-bold-outline"></v-icon>
                          </div>
                          <div v-if="config.content.sections[0].nominations.length > 1"
                               class="delete ml-5"
                               @click.stop="deleteNomination(nominationIndex)">
                            <v-icon class="icon-item icon-delete-outline"></v-icon>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="level2">
                        <div v-for="(field, fieldIndex) in nomination.fields" :key="'nomination_' + nominationIndex + '_field_' + fieldIndex" class="list-item">
                          <div class="list-item-name">
                            <span>
                              <h4 v-if="fieldIndex === 0">Позиция</h4>
                              <q>{{ nominationIndex + 1 }}.{{ fieldIndex + 1 }}.</q>
                              <v-combobox
                                  v-model="field.name"
                                  :items="indicators"
                                  item-text="name"
                                  return-object
                                  label="Наименование позиции"
                                  hide-no-data
                                  :hint="field.id ? `ID: ${field.id}` : `Будет создана новая позиция`"
                                  persistent-hint
                                  allow-overflow
                                  class="mb-2"
                                  @input="val => changeParameterName(field, val)"
                              >
                                </v-combobox>
                            </span>
                            <div v-if="nomination.fields.length > 1"
                                 class="delete"
                                 @click="fieldDown(nomination, fieldIndex)"
                                 @click.stop=""
                            >
                              <v-icon class="icon-item icon-arrow-down-bold-outline"></v-icon>
                            </div>
                            <div v-if="nomination.fields.length > 1"
                                 class="delete ml-5"
                                 @click="fieldUp(nomination, fieldIndex)"
                                 @click.stop=""
                            >
                              <v-icon class="icon-item icon-arrow-up-bold-outline"></v-icon>
                            </div>
                            <div v-if="nomination.fields.length > 1"
                                 class="delete ml-5"
                                 @click="deleteField(nominationIndex, fieldIndex)"
                                 @click.stop="">
                              <v-icon class="icon-item icon-delete-outline"></v-icon>
                            </div>
                          </div>
                          <div class="list-item-description">
                            <v-text-field v-model="field.description" label="Описание позиции"></v-text-field>
                          </div>
                          <div class="list-item-type">
                            <table>
                              <tr>
                                <td>
                                  <v-select
                                      label="Тип позиции"
                                      :items="fieldsType"
                                      item-text="title"
                                      item-value="type"
                                      v-model="field.type"
                                  ></v-select>
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                      <a class="configAdd" style="margin-left: 80px; margin-top: -24px!important;" href="#"
                         @click.prevent="addField(nomination)">+ добавить позицию</a>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <a class="configAdd" href="#" @click.prevent="addNomination()">+ добавить номинацию</a>
            </div>

            <div class="chapterName">Регионы</div>
            <div class="region-list-wrap">
              <div class="region-buttons">
                <v-btn color="primary" outlined @click="selectAllRegions">Выделить все</v-btn>
                <v-btn color="primary" outlined @click="config.content.sections[0].regions = []">Снять выделения</v-btn>
              </div>
              <div class="region-list">
                <div v-for="(region, regionIndex) in regionList" :key="'regionIndex' + regionIndex">
                  <v-checkbox
                      :label="region.name"
                      :value="region.code"
                      v-model="config.content.sections[0].regions"
                  ></v-checkbox>
                </div>
              </div>
            </div>

            <div class="save-buttons mt-24">
              <v-btn color="primary" outlined @click="$router.push('/config/event')">Отмена</v-btn>
              <v-btn :disabled="submitButtonDisabled" color="primary" @click="submit()">Сохранить</v-btn>
            </div>
          </template>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>
import api from "@/modules/api";
import {getDomainConfigById, saveDomainConfig, updateDomainConfig} from "@/modules/api.configs";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";
import {downloadFile, uploadFile} from "@/modules/api.files";
import {
  createIndicator,
  getIndicatorsByContextAndIndicatorId,
  getIndicatorsByDomain,
  saveIndicators
} from "@/modules/api.indicators";

export default {
  name: "EventConfigEdit",
  props: {},
  components: {LoaderDialog},
  data() {
    return {
      config: {
        id: null,
        content: {
          sections: [{
            title: null,
            description: null,
            context: null,
            beginDate: null,
            endDate: null,
            regions: [],
            nominations: [{
              name: "",
              fields: [{
                id: "",
                name: "",
                description: "",
                type: "FILE"
              }]
            }],
          }]
        },
      },
      domain: 'event',
      attachments: [],
      indicators: [],
      attachmentsIndicatorId: '09aea882-0dee-4b86-9b85-7ab5ab2aaaa5',
      startDateMenu: false,
      endDateMenu: false,
      minDate: '2022-01-01',
      maxDate: '2030-12-31',
      errorText: null,
      submitButtonDisabled: false,
      apiLoaded: false,
      isNewConfig: true,
      attachmentType: [
        {type: 'all', title: 'Для всех'},
        {type: 'participant', title: 'Только для участников'},
        {type: 'controller', title: 'Только для проверяющих'},
      ],
      fieldsType: [
        {type: 'FILE', title: 'Файл', description: 'Прикрепление файла в позиции'},
        {type: 'LINK', title: 'Ссылка', description: 'Добавление ссылки в позиции'},
        {type: 'TEXT', title: 'Текст', description: 'Добавление текста в позиции'},
      ],
      typeDescription: 'Прикрепление файла в позиции',
      regionList: null,
      loading: false,
    };
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    changeParameterName(field, val) {
      if (val && typeof val === 'object') {
        field.id = val.id
        field.name = val.name
      } else {
        field.id = null
      }
    },
    async loadConfig(id) {
      this.loading = true
      let req = await getDomainConfigById("event", id)
      if (req.ok) {
        this.config = req.payload
        this.apiLoaded = true
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.loading = false
    },
    async loadIndicators() {
      let req = await getIndicatorsByDomain(this.domain)
      if (req.ok) {
        this.indicators = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },
    async loadAttachments() {
      let req = await getIndicatorsByContextAndIndicatorId(
          this.domain,
          this.config.content.sections[0].context,
          this.attachmentsIndicatorId
      )
      if (req.ok) {
        const value = req.payload
        if (value && value.length && value[0].value) {
          this.attachments = JSON.parse(value[0].value)
        }
      } else {
        alert('Ошибка загрузки данных о статических файлах')
      }
    },

    async createConfig() {
      await this.submit(this.isNewConfig);
      await this.loadConfig(this.id);
    },

    async addAttachment() {
      if (!this.config.content.sections[0].context) {
        alert("Для прикрепления файлов заполните поле Код")
        return
      }

      this.loading = true
      let file = this.$refs['attachmentInput'].files[0];
      if (file) {
        const uploadedFile = await uploadFile(file)
        this.attachments.push({
          id: uploadedFile.id,
          name: uploadedFile.name,
          role: "all",
        })
      }

      this.$refs['attachmentInput'].value = ""
      this.$forceUpdate()
      this.loading = false
    },

    async deleteAttachment(attachment) {
      this.attachments.splice(this.attachments.findIndex(e => e.id === attachment.id), 1);
      this.$forceUpdate()
    },

    async downloadAttachment(attachment) {
      this.loading = true
      await downloadFile(attachment.id, attachment.name)
      this.loading = false
    },

    async saveAttachments() {
      const savedValues = [{
        indicatorId: this.attachmentsIndicatorId,
        context: this.config.content.sections[0].context,
        regionCode: "",
        value: JSON.stringify(this.attachments),
        type: 'FILES'
      }]
      await saveIndicators(savedValues)
    },

    deleteNomination(nominationIndex) {
      this.config.content.sections[0].nominations.splice(nominationIndex, 1);
      this.$forceUpdate()
    },

    deleteField(nominationIndex, fieldIndex) {
      const nomination = this.config.content.sections[0].nominations[nominationIndex];
      if (nomination && nomination.fields[fieldIndex]) {
        nomination.fields.splice(fieldIndex, 1);
      }
      this.$forceUpdate()
    },

    addNomination() {
      if (this.config.content.sections[0].nominations == null) {
        this.config.content.sections[0].nominations = []
      }
      this.config.content.sections[0].nominations.push({
        name: "",
        fields: [{
          id: "",
          name: "",
          description: "",
          type: "FILE"
        }]
      })
      this.$forceUpdate();
    },

    addField(nomination) {
      nomination.fields.push({
        id: "",
        name: "",
        description: "",
        type: "FILE"
      })
      this.$forceUpdate();
    },

    nominationUp(nominationIndex) {
      if (nominationIndex > 0) {
        const nominations = this.config.content.sections[0].nominations
        const nominationToMove = nominations[nominationIndex]
        nominations.splice(nominationIndex, 1)
        nominations.splice(nominationIndex - 1, 0, nominationToMove)
      }
    },
    nominationDown(nominationIndex) {
      const nominations = this.config.content.sections[0].nominations
      if (nominationIndex < nominations.length - 1) {
        const nominationToMove = nominations[nominationIndex]
        nominations.splice(nominationIndex, 1)
        nominations.splice(nominationIndex + 1, 0, nominationToMove)
      }
    },
    fieldUp(nomination, fieldIndex) {
      if (fieldIndex > 0) {
        const fields = nomination.fields
        const fieldToMove = fields[fieldIndex]
        fields.splice(fieldIndex, 1)
        fields.splice(fieldIndex - 1, 0, fieldToMove)
      }
    },
    fieldDown(nomination, fieldIndex) {
      const fields = nomination.fields
      if (fieldIndex < fields.length - 1) {
        const fieldToMove = fields[fieldIndex]
        fields.splice(fieldIndex, 1)
        fields.splice(fieldIndex + 1, 0, fieldToMove)
      }
    },

    validateConfig(config) {
      if (config.content.sections[0].title == null || config.content.sections[0].title.length < 1) {
        return "Должно быть указано наименование конкурса"
      }
      if (config.content.sections[0].description == null || config.content.sections[0].description.length < 1) {
        return "Должно быть указано описание конкурса"
      }
      if (config.content.sections[0].context == null || config.content.sections[0].context.length < 1) {
        return "Должен быть указан код конкурса"
      }
      if (config.content.sections[0].beginDate == null || config.content.sections[0].beginDate.length < 1) {
        return "Должна быть указана дата начала проведения конкурса"
      }
      if (config.content.sections[0].endDate == null || config.content.sections[0].endDate.length < 1) {
        return "Должна быть указана дата окончания проведения конкурса"
      }

      let beginDate = "", endDate = "";
      if (config.content.sections[0].beginDate.length < 11) {
        beginDate = new Date(config.content.sections[0].beginDate + " 03:00:00")
      } else {
        beginDate = new Date(config.content.sections[0].beginDate)
      }
      if (config.content.sections[0].endDate.length < 11) {
        endDate = new Date(config.content.sections[0].endDate + " 23:59:59")
      } else {
        endDate = new Date(config.content.sections[0].endDate)
      }
      if (beginDate > endDate) {
        return "Дата начала проведения конкурса должна быть меньше даты окончания проведения конкурса"
      }

      let validNom = this.validateNomination(config.content.sections[0].nominations);
      if (!(validNom === true)) {
        return validNom
      }
      if (config.content.sections[0].regions.length === 0) {
        return "Должен быть выбран хотя бы один регион"
      }
      return true
    },

    validateNomination(nominations) {
      if (nominations == null || nominations.length < 1) {
        return "Должна быть хотя бы одна номинация"
      }
      for (let i = 0; i < nominations.length; i++) {
        let nomination = nominations[i]
        if (nomination.name === "" || nomination.name.length === 0) {
          return "Должно быть указано наименование у номинации " + (i + 1)
        }
        for (let j = 0; j < nomination.fields.length; j++) {
          let field = nomination.fields[j]
          if (field.name == null || field.name === '' ||
              field.description == null || field.description === '' ||
              field.type == null || field.type === ''
          ) {
            return "Должны быть указаны все элементы позиции " + (j + 1) + " номинации " + (i + 1)
          }
        }
      }
      return true
    },

    //Досоздает индикаторы, которые не нашлись по названию
    async createIndicators() {
      for (const nomination of this.config.content.sections[0].nominations) {
        for (const field of nomination.fields) {
          if (!field.id || !field.id.length) {
            const indicatorData = {
              domain: {
                "code": this.domain,
              },
              name: field.name,
              dataType: 'STRING',
            };

            const indicator = (await createIndicator(indicatorData)).payload
            field.id = indicator.id
          }
        }
      }
    },

    loadJson() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this
      reader.onload = function () {
        let nominations
        try {
          nominations = JSON.parse(reader.result)
        } catch (e) {
          reader.componentThis.errorText = "Файл должен содержать номинации в формате JSON"
          return
        }
        let validStat = reader.componentThis.validateNomination(nominations.nominations)
        if (validStat === true) {
          reader.componentThis.config.content.sections[0].nominations = nominations.nominations
        } else {
          reader.componentThis.errorText = validStat
        }
      };
      this.$refs['fileInput'].value = ""
      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    async submit(isNewConfig) {
      this.submitButtonDisabled = true

      if (isNewConfig) {
        let req = await saveDomainConfig(this.domain, this.config.content)
        if (req.ok) {
          this.id = req.payload.id
        } else {
          this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        }
      } else {
        let validStat = this.validateConfig(this.config)
        if (validStat === true) {
          await this.createIndicators()

          let req = await updateDomainConfig(this.domain, this.config.id, this.config.content)
          if (req.ok) {
            await this.saveAttachments()
            await this.$router.push("/config/event")
          } else {
            this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
          }
        } else {
          this.errorText = validStat
        }
      }
      this.submitButtonDisabled = false
    },

    async getRegionList() {
      let req = await api.get("/getRegionsList");
      if (req.ok) {
        this.regionList = req.payload;
        this.regionList.sort((a, b) => (a.name > b.name ? 1 : -1));
      } else {
        console.log('get regionList error');
      }
    },

    selectAllRegions() {
      for (let region of this.regionList) {
        this.config.content.sections[0].regions.push(region.code);
      }
    },

    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },
  },

  async beforeMount() {
    this.id = this.$route.params.id;
    await this.getRegionList();
    await this.loadIndicators();
    if (this.id != null) {
      await this.loadConfig(this.id);
      await this.loadAttachments();
      this.isNewConfig = false;
    } else {
      this.apiLoaded = true;
    }
  }
}
</script>

<style lang="scss">
@import "../../../styles/main.scss";
</style>

<style lang="scss" scoped>
.chapterName {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  margin-top: 30px;
}

.file-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-bottom: 20px;

  .v-icon {
    margin-right: 10px;
    color: #0033a0;
  }
}

.list-item-description {
  .v-input {
    width: calc(100% - 100px);
    flex-grow: 0;
    margin-left: 30px;
  }
}

.list-item-type {
  tr {
    margin-top: -20px;
  }
  span {
    width: 100px;
    margin-left: 30px;
  }
  .v-input {
    width: calc(100% - 100px);
    //flex-grow: 0;
    margin-left: 30px;
  }
  .v-select {
    margin-top: -20px;
    margin-bottom: -25px;
    width: 250px;
    .v-label {
      color: $cgray!important;
    }
  }
  td:last-child {
    margin-left: -100px;
    color: #777779;
  }
}

.region-list-wrap {
  .region-buttons {
    display: flex;
    margin: 10px 0;
    .btn, button {
      margin-right: 8px;
      height: 30px;
    }
  }
  .region-list {
    max-height: 44vh;
    overflow-y: auto;
    .v-input--checkbox {
      margin: 0 0;
    }
  }

}

.edit, .delete {
  margin-left: -36px;
}

.level2 {
  padding-left: 80px !important;
}

</style>
